<template>
  <div>
    <LiefengContent>
      <template v-slot:title>维度管理</template>
      <template v-slot:toolsbarLeft></template>
      <template v-slot:toolsbarRight>
        <Input
        :maxlength="20"
        placeholder="请输入维度名称"
        v-model.trim="selectName"
        style="width: 200px; margin-right: 6px"
      ></Input>
      <Button type="primary" @click="getList">搜索</Button>
      <Button type="success" @click="reset">重置</Button>
      <Button type="primary" @click="addDmension" icon="ios-add">新增</Button>
      <Button type="error" @click="$router.push('/institutional')">返回</Button>
      </template>
      <template v-slot:contentArea>
          <LiefengTable
          :talbeColumns="talbeColumns"
          :tableData="tableData"
          :loading="loading"
          :fixTable="true"
          :curPage="page"
          :total="total"
          :pagesizeOpts="[20, 30, 50, 100]"
          :page-size="pageSize"
          @hadlePageSize="hadlePageSize"
          ></LiefengTable>
           <!--新增维度-->
          <LiefengModal :value="messageStatus"  @input="messageInput" :title="isAdd?'新增维度':'修改维度'" :fullscreen="false" width="500px" height="300px">
              <template v-slot:contentarea>
                  <Form label-position="left" :label-width="100" style="width:90%">
                     <FormItem>
                        <span slot="label" class="validate">维度名称:</span>
                        <Input
                          v-model.trim="formData.name"
                          :maxlength="20"
                          placeholder="请输入维度名称"
                        />
                     </FormItem>
                     <FormItem>
                        <span slot="label" class="validate">维度编码:</span>
                        <Input
                          v-model.trim="formData.code"
                          :maxlength="20"
                          placeholder="请输入维度编码"
                        />
                     </FormItem>
                     <FormItem>
                        <span slot="label">维度说明:</span>
                        <Input
                          type="textarea"
                          v-model.trim="formData.dimensionDesc"
                          :maxlength="100"
                          placeholder="请输入维度说明"
                          :row="4"
                        />
                     </FormItem>
                  </Form>
              </template>
              <template v-slot:toolsbar>
                <Button type="info" @click="messageInput(false)" >取消</Button>
                <Button style="margin: 0 5px 0 13px" :disabled="sendDisabled" type="primary" @click="messageSend">确定</Button>
              </template>
          </LiefengModal>
      </template>
    </LiefengContent>
  </div>
</template>


<script>
import LiefengContent from "@/components/LiefengContent";
import LiefengTable from "@/components/LiefengTable";
import LiefengModal from "@/components/LiefengModal";
export default {
  data() {
    return {
      talbeColumns:[
        {
          title: "维度名称",
          key: "name",
          minWidth: 200,
          align: "center"
        },
        {
          title: "维度编码",
          key: "code",
          minWidth: 200,
          align: "center"
        },
        {
          title: "维度说明",
          key: "dimensionDesc",
          minWidth: 200,
          align: "center"
        },
        {
          title: '操作',
          key: 'action',
          fixed: 'right',
          align: "center",
          minWidth: 180,
          render: (h, params) => {
            return h('div', [
              h('Button', {
                props: {
                  type: 'primary',
                  size: 'small',
                },
                style: {
                },
                on: {
                  click: () => {
                    this.isAdd = false
                    this.formData = {}
                    this.getDetail(params.row.id)
                  }
                }
              }, '修改'),
              h('Button', {
                props: {
                  type: 'error',
                  size: 'small',
                },
                style: {
                  marginLeft:'10px'
                },
                on: {
                  click: () => {
                    this.$Modal.confirm({
                      title: "温馨提示",
                      content: "您正在删除当前数据，是否继续？",
                      onOk: () => {
                          this.deleteRow(params.row.id)
                      },
                    });
                  }
                }
              }, '删除'),
            ]);
          }
        }
      ],
      tableData:[],
      loading:false,
      page:1,
      pageSize:20,
      total:0,
      // 搜索名称
      selectName:'',
      // 模态框
      messageStatus:false,

      formData:{
        name:'',
        dimensionDesc:'',
        code:'',
        oemCode:parent.vue.oemInfo.oemCode
      },

      isAdd:false


    };
  },
  methods: {
    // 重置按钮
    reset(){
      this.selectName = ''
      this.page = 1
      this.pageSize = 20
      this.getList()
    },
    // 确定按钮
    messageSend(){
      if(this.formData.name == '' || !this.formData.name){
        this.$Message.warning({
          content:'请填写维度名称',
          background:true
        })
        return
      }
      if(this.formData.code == '' || !this.formData.code){
        this.$Message.warning({
          content:'请填写维度编码',
          background:true
        })
        return
      }
      if(this.isAdd == true){
         this.addRow()
      }else{
        this.updateRow()
      }
     
    },
    messageInput(status){
      this.messageStatus = status
    },
    // 点击新增按钮
    addDmension(){
      this.formData = {
        name:'',
        dimensionDesc:''
      }
      this.isAdd = true
      this.messageStatus = true
    },
    hadlePageSize(val){
      this.page = val.page
      this.pageSize = val.pageSize
      this.getList()
    },

    // 接口部分
    getList(){
        this.loading = true
        this.$get('/gx/pc/dimension/selectPage',{
          page:this.page,
          pageSize:this.pageSize,
          oemCode:parent.vue.oemInfo.oemCode,
          name:this.selectName
        }).then(res=>{
          this.loading = false
          if(res.code == 200){
              this.tableData = res.dataList
              this.currentPage = res.currentPage
              this.total = res.maxCount
          }else{
            this.$Message.error({
              content:'获取维度列表信息失败',
              background:true
            })
            return
          }
        })
    },
    //删除接口
    deleteRow(id){
      this.$post('/gx/pc/dimension/delete',{id}).then(res=>{
        if(res.code == 200){
          this.$Message.success({
            content:'删除成功',
            background:true
          })
          this.page = this.currentPage
          this.getList()
        }else{
          this.$Message.error({
            content:res.desc,
            background:true
          })
          return
        }
      })
    },
    // 新增
    addRow(){
      this.$post('/gx/pc/dimension/saveDimension',{
        ...this.formData
      }).then(res=>{
        if(res.code == 200){
          this.$Message.success({
            content:'新增维度成功',
            background:true
          })
          this.page = this.currentPage
          this.messageStatus = false
          this.getList()
        }else{
          this.$Message.error({
            content:res.desc,
            background:true
          })
          return
        }
      })
    },
    // 获取详情
    getDetail(id){
      this.$get('/gx/pc/dimension/findById',{id}).then(res=>{
        if(res.code == 200){
          this.formData = res.data
          this.messageStatus = true
        }else{
          this.$Message.error({
            content:'获取维度详情失败',
            background:true
          })
          return
        }
      })
    },
    // 修改维度
    updateRow(){
      this.$post('/gx/pc/dimension/updateDimension',{
        ...this.formData
      }).then(res=>{
        if(res.code == 200){
          this.$Message.success({
            content:'修改维度成功',
            background:true
          })
          this.page = this.currentPage
          this.messageStatus = false
          this.getList()
        }else{
          this.$Message.error({
            content:res.desc,
            background:true
          })
          return
        }
      })
    }
  },
  created() {
    this.getList()
  },

  components: {
    LiefengContent,
    LiefengTable,
    LiefengModal
  }
};
</script>

<style lang="less" scoped>
/deep/.validate {
   &::before {
    content: "*";
    display: inline-block;
    margin-right: 4px;
    line-height: 1;
    font-family: SimSun;
    font-size: 14px;
    color: #ed4014;
  }
}
</style>    